import './styles/site.sass';

if ('steffi-trinker.de' === process.env.GATSBY_SITE_NAME) {
    import ('./styles/steffi-trinker.de.sass');
} else if ('djfrancoisfrommage.de' === process.env.GATSBY_SITE_NAME) {
    import ('@fontsource/montserrat/latin-400.css');
    import ('@fontsource/montserrat/latin-600.css');
    import ('@fontsource/lato/latin-700.css');
    import ('@fontsource/lato/latin-900.css');
    import ('./styles/djfrancoisfrommage.de.sass');
} else {
    import ('@fontsource/source-sans-pro/latin-400.css');
    import ('@fontsource/source-sans-pro/latin-600.css');
    import ('@fontsource/source-sans-pro/latin-700.css');
    import ('@fontsource/source-sans-pro/latin-900.css');
    import ('./styles/default.sass');
}

export const onRouteUpdate = () => {
    // handle consent
    setTimeout(() => {
        dispatchEvent(new Event('myth--content-refreshed'));
    }, 250);
    setTimeout(() => {
        dispatchEvent(new Event('myth--content-refreshed'));
    }, 1000);
};
